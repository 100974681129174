import { Http } from '../instance/instance';
import domain from '@/scripts/domain';
import ContentTypeEnum from '../instance/contentType';
const qs = require('qs');

// 默认contentType为 multipart/form-data
const request = new Http(domain['examination-single']['exam-api-domain'], ContentTypeEnum.FORM_DATA, {
  onResponse(response) {
    if (response.status === 200) return response.data;
    return Promise.reject(response);
  },
});

const ScoreReportRequest = new Http(domain['examination-single']['exam-api-domain'], ContentTypeEnum.FORM_DATA, {
  onResponse(response) {
    if (response.status === 200)
      return Object.prototype.hasOwnProperty.call(response.data, 'data')
        ? response.data.data
        : response.data;
    return Promise.reject(
      response.response && response.response.data && response.response.data.message
        ? response.response.data
        : response
    );
  },
});

export const trimEmptyParams = (obj, exitNumberKey = [], exitStringKey = []) => {
  let result = { ...obj };
  Object.keys(result).forEach((key) => {
    if (!result[key]) {
      if (exitNumberKey.indexOf(key) > -1 && result[key] === 0) return;
      if (exitStringKey.indexOf(key) > -1 && result[key] === '') return;
      delete result[key];
    }
  });
  return result;
};

export default {
  getUser: (userId) => {
    return request.get('/v1/school/markers/list', { marker_ids: [userId].toString() });
  },
  getExam: (examId, schoolId = 0) => {
    // schoolId 若没有则为0
    return request.get(`/v1/exams/${schoolId}/${examId}`);
  },
  // 获取考试区域信息
  getDistricts: (examId) => {
    return ScoreReportRequest.get(`/v1/scorereport/districts`, { exam_id: examId });
  },
  // 获取考试学校班级信息
  getSchoolAndClasses: ({ examId, cityId, districtIds, schoolIds }) => {
    const params = {
      exam_id: examId,
      city_id: cityId,
      district_id: districtIds && districtIds.join(','),
      school_id: schoolIds && schoolIds.join(','),
    };
    return ScoreReportRequest.get(`/v1/scorereport/schoolclasses`, trimEmptyParams(params));
  },
  // 获取学生成绩单
  async getStudentScores({
    examId,
    cityId,
    districtId,
    schoolId,
    classId,
    rankType,
    keyword,
    page,
    size,
    order,
    asc,
  }) {
    const params = trimEmptyParams({
      exam_id: examId,
      city_id: cityId,
      district_id: districtId,
      school_id: schoolId,
      class_id: classId,
      rank_type: rankType,
      keyword,
      page,
      size,
      order,
      asc,
    });
    if (params.asc) {
      params.asc = params.asc === 'ascending' ? 0 : 1;
    }
    let res = await ScoreReportRequest.get(`/v1/scorereport/studentscores`, params);
    if (res) {
      const { page, scores, size: pageSize, total_count: totalCount, total_page: totalPage } = res;
      return {
        page,
        pageSize,
        totalCount,
        totalPage,
        list: scores || [],
      };
    } else {
      return Promise.reject();
    }
  },
  exportStudentScores: ({
    examId,
    cityId,
    districtId,
    schoolId,
    classId,
    rankType,
    keyword,
    columns,
    order,
    asc,
    rank_show_type
  }) => {
    const params = trimEmptyParams({
      exam_id: examId,
      city_id: cityId,
      district_id: districtId,
      school_id: schoolId,
      class_id: classId,
      rank_type: rankType,
      keyword,
      fields: columns,
      order,
      asc,
    });
    if (params.asc) {
      params.asc = params.asc === 'ascending' ? 0 : 1;
    }
    params.rank_show_type = rank_show_type
    return ScoreReportRequest.get(`/v1/scorereport/studentscores/export`, params, {
      responseType: 'blob',
    });
  },
  // 教研员查看成绩统计报表
  async getAreaAdminStatistics(params) {
    params = trimEmptyParams(params);
    if (params.F_asc) {
      params.F_asc = params.F_asc === 'ascending' ? 1 : 0;
    }
    let res = await ScoreReportRequest.get(`/v1/scorereport/statistics/admin`, params);
    if (params.F_type === 1) {
      // 汇总表, 不用分页
      return res;
    } else {
      // 明细表, 需要分页
      if (res) {
        const { page, list, page_size: pageSize, total: totalCount } = res;
        return {
          page,
          pageSize,
          totalCount,
          totalPage: Math.ceil(totalCount / pageSize),
          list,
        };
      } else {
        return Promise.reject();
      }
    }
  },
  // 学校查看成绩统计报表
  getAreaSchoolStatistics: (params) => {
    return ScoreReportRequest.get(`/v1/scorereport/statistics/school`, trimEmptyParams(params));
  },
  // 教研员导出成绩统计报表
  exportAreaAdminStatistics: (params) => {
    return ScoreReportRequest.get(
      `/v1/scorereport/statistics/admin/export`,
      trimEmptyParams(params),
      {
        responseType: 'blob',
      }
    );
  },

  // 学校导出成绩统计报表
  exportAreaSchoolStatistics: (params) => {
    return ScoreReportRequest.get(
      `/v1/scorereport/statistics/school/export`,
      trimEmptyParams(params),
      {
        responseType: 'blob',
      }
    );
  },
  getExamFullMarks(params) {
    return ScoreReportRequest.get(`/v1/scorereport/fullmarks`, trimEmptyParams(params));
  },
  // 分数线数据
  getLevelReport: (params) => {
    return ScoreReportRequest.get(`/v1/scorereport/level`, trimEmptyParams(params));
  },

  // 导出分数线数据
  exportLevelReport: (params) => {
    return ScoreReportRequest.get(`/v1/scorereport/level/export`, trimEmptyParams(params), {
      responseType: 'blob',
    });
  },
  // 查看试卷分析成绩明细
  async getPaperStudentDetail(params) {
    params = trimEmptyParams(params);
    if (params.F_asc) {
      params.F_asc = params.F_asc === 'ascending' ? 1 : 0;
    }
    let res = await ScoreReportRequest.get(`/v1/scorereport/exampaper/score-detail`, params);
    if (res) {
      const { page, list, page_size: pageSize, total: totalCount } = res;
      return {
        page,
        pageSize,
        totalCount,
        totalPage: Math.ceil(totalCount / pageSize),
        list,
      };
    } else {
      return Promise.reject();
    }
  },
  // 试卷分析成绩明细导出
  exportPaperStudentDetail: (params, cancelTokenKey) => {
    params = trimEmptyParams(params);
    if (params.F_asc) {
      params.F_asc = params.F_asc === 'ascending' ? 1 : 0;
    }
    return ScoreReportRequest.get(`/v1/scorereport/exampaper/score-detail/export`, params, {
      responseType: 'blob',
      cancelTokenKey,
    });
  },
  // 试卷分析
  getPaperStat: (params) => {
    return ScoreReportRequest.get(`/v1/scorereport/paper_stat`, trimEmptyParams(params));
  },
  // 题目明细分析
  getPaperQuestionStat: (params) => {
    return ScoreReportRequest.get(`/v1/scorereport/paper_qst_stat`, trimEmptyParams(params));
  },
  // 题目明细分析导出
  exportPaperQuestionStat: (params) => {
    return ScoreReportRequest.get(
      `/v1/scorereport/paper_qst_stat/export`,
      trimEmptyParams(params),
      {
        responseType: 'blob',
      }
    );
  },

  /** 高中年级成绩报表 start ***/
  getClassList(params) {
    return ScoreReportRequest.get(
      '/v1/school/classes', params)
  },

  // 获取高中学生成绩单
  getHighScore(params) {
    return ScoreReportRequest.get(
      '/v1/scorereport/high/studentScores', params)
  },

  // 导出高中学生成绩单
  exportHighStudentScores(params) {
    return ScoreReportRequest.get(`/v1/scorereport/exportHighStudentScores`, params, {
      responseType: 'blob',
    });
  },

  // 导出高中学生成绩单(等级分)
  exportScoresWithRankScore(params) {
    return ScoreReportRequest.get(`/v1/scorereport/exportHighStudentScoresWithRankScore`, params, {
      responseType: 'blob',
    });
  },

  // 学生小题得分
  studentBlockScores(params) {
    return ScoreReportRequest.get(
      '/v1/scorereport/high/studentBlockScores', params)
  },
  // 导出学生小题得分
  exportStudentBlockScores(params) {
    return ScoreReportRequest.get(`/v1/scorereport/exportStudentBlockScores`, params, {
      responseType: 'blob',
    });
  },

  // 导出选择题作答情况
  exportChoiceQuestionDetail(params) {
    return ScoreReportRequest.get(`/v1/scorereport/exportChoiceQuestionDetail`, params, {
      responseType: 'blob',
    });
  },

  // 小题得分明细
  blockScoreDetail(params) {
    return ScoreReportRequest.get(
      '/v1/scorereport/high/blockScoreDetail', params)
  },
  // 导出小题得分明细
  exportBlockScoreDetail(params) {
    return ScoreReportRequest.get(`/v1/scorereport/exportBlockScoreDetail`, params, {
      responseType: 'blob',
    });
  },

  // 小题平均分
  blockAvgScore(params) {
    return ScoreReportRequest.get(
      '/v1/scorereport/high/blockAvgScore', params)
  },
  // 导出小题平均分
  exportBlockAvgScore(params) {
    return ScoreReportRequest.get(`/v1/scorereport/exportBlockAvgScore`, params, {
      responseType: 'blob',
    });
  },

  // 获取分数段详细报表
  scoreSection(params) {
    return ScoreReportRequest.get(
      '/v1/scorereport/high/scoreSeg', params)
  },

  // 获取名次段详细报表
  rankSection(params) {
    return ScoreReportRequest.get(
      '/v1/scorereport/high/rankSeg', params)
  },

  // 各科总分
  subjectFullScore(params) {
    return ScoreReportRequest.get(
      '/v1/scorereport/high/subjectFullScore', params)
  },

  // 获取名次构成结果
  rankComposition(data) {
    return ScoreReportRequest.post(
      '/v1/scorereport/high/rankComposition', data)
  },

  // 获取任务进度
  getJobProgress(params) {
    return request.post(
      '/v1/flow_forward/ess/get/v1/joint/job_queue/job/progress',
      qs.stringify(params),
      "application/x-www-form-urlencoded",
    )
  },

  // 学生等级分
  getStudentRankScore(data) {
    return request.post(
      '/v1/scorereport/high/rankScore/student',
      data
    )
  },

  // 获取等级分等级设置的最新配置
  getLevelConfig(params) {
    return ScoreReportRequest.get(
      '/v1/scorereport/high/rankScore/config', params)
  },

  // 提交等级分应用任务
  postRankScore(data) {
    return request.post(
      '/v1/flow_forward/ess/put/v1/joint/job_queue/post/rank_score',
      qs.stringify(data),
      "application/x-www-form-urlencoded",
    )
  },

  // 单位平均等级分
  levelScoreAvg(data) {
    return request.post(
      '/v1/scorereport/high/rankScore/avg',
      data
    )
  },

  postSetting(data) {
    return request.post(
      '/v1/scorereport/high/latestConfig',
      data
    )
  },

  getSetting(params) {
    return ScoreReportRequest.get(
      '/v1/scorereport/high/latestConfig', params)
  },

  // 划线工具分值计算
  lineCalc(params) {
    return ScoreReportRequest.get(
      '/v1/scorereport/high/onlineAnalysis/calc', params)
  },

  getLineAnalysis(data) {
    return ScoreReportRequest.post(
      '/v1/scorereport/high/onlineAnalysis/stat', data)
  },

  getExcellentStudentList(data) {
    return ScoreReportRequest.post(
      '/v1/scorereport/high/excellenceStudent', data)
  },

  getCriticalStuList(data) {
    return ScoreReportRequest.post(
      '/v1/scorereport/high/criticalStudent', data)
  },

  getAnalysisOverview(data) {
    return ScoreReportRequest.post(
      '/v1/scorereport/high/onlineAnalysis/overview', data)
  },

  // export
  // 导出分数段
  exportScoreSeg(data) {
    return ScoreReportRequest.post(`/v1/scorereport/scoreSeg/exportSingle`, data, 'application/x-www-form-urlencoded;charset=UTF-8', {
      responseType: 'blob',
    })
  },
  // 导出名次段
  exportRankSeg(data) {
    return ScoreReportRequest.post('/v1/scorereport/high/rankSeg/export', data, 'application/x-www-form-urlencoded;charset=UTF-8', {
      responseType: 'blob',
    });
  },
  // 导出名次构成
  exportRankComposition(data) {
    return ScoreReportRequest.post(`/v1/scorereport/high/rankComposition/export`, data, 'application/x-www-form-urlencoded;charset=UTF-8', {
      responseType: 'blob',
    });
  },
  // 上线率分析导出
  exportOnlineAnalysis(data) {
    return ScoreReportRequest.post(`/v1/scorereport/high/onlineAnalysis/stat/export`, data, 'application/x-www-form-urlencoded;charset=UTF-8', {
      responseType: 'blob',
    });
  },

  // 学优生名单导出
  exportExcellenceStudent(data) {
    return ScoreReportRequest.post(`/v1/scorereport/high/excellenceStudent/export`, data, 'application/x-www-form-urlencoded;charset=UTF-8', {
      responseType: 'blob',
    });
  },
  // 临界生名单导出
  exportCriticalStudent(data) {
    return ScoreReportRequest.post(`/v1/scorereport/high/criticalStudent/export`, data, 'application/x-www-form-urlencoded;charset=UTF-8', {
      responseType: 'blob',
    });
  },

  // 高中报告打包下载
  packExport(params) {
    return ScoreReportRequest.get(`/v1/scorereport/high/pack/export`, params, {
      responseType: 'blob',
      timeout: 120000, // 设置超时时间为 2 分钟（120000 毫秒）
    })
  },

  // 小初考试报告打包下载
  oldPackExport(params) {
    return ScoreReportRequest.get(`/v1/scorereport/old/pack/export`, params, {
      responseType: 'blob',
    })
  },

  gradeBook(params) {
    return ScoreReportRequest.get(`/v1/scorereport/exportGradeBook`, params, {
      responseType: 'blob',
    })
  },

  // 学生选择题作答情况
  getChoiceStat(params) {
    return ScoreReportRequest.get(
      '/v1/scorereport/choice_question_stat', params)
  }
  /*** 高中年级成绩报表 end ***/
};
